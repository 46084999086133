import React, {useEffect, useState} from "react";
import Layout from '../components/staticLayout';
import ComponentLoader from '../components/componentLoader';
import MetaDetails from "../components/meta";
import { PAGES_ENDPOINT, SITENAME } from '../util/constants';

const PrivacyNoticePage = () => {
  const [pageDetails, setpageDetails] = useState (null);
  useEffect (()=> {
    //console.log(PAGES_ENDPOINT);
    const url = PAGES_ENDPOINT + 'privacy';
    fetch(url).then(res=>res.json()).then(res=>{ setpageDetails(res) })
  }, [])
  return (
    <Layout >
      {pageDetails && <ComponentLoader comp={pageDetails.data.buckets} />}
    </Layout>
  )
}

export default PrivacyNoticePage
export const Head = () => (<MetaDetails meta={{
  "title": `Privacy Notice - ${SITENAME}`,
  "description": `${SITENAME} Privacy Notice description`,
}} />)